import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

import { PopupButton } from "react-calendly";

const Features = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="Schedule a Call">
            <section className="content-header content-header-1 -blue">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="heading">Want a Call?</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta cta-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">

                            <PopupButton text="Click here to schedule a call!" url="https://calendly.com/djmanager/talk-with-us" className="btn flat-btn -blue -rounded -single" />

                        </div>
                    </div>
                </div>
            </section>

            <section className="content content-2 -bg-grey">
                <div className="container">

                    <div className="row">
                        
                        

                    </div>

                </div>
            </section>

        </WebsiteLayout>
    </main>
  )
}

export default Features;

